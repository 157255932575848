import axios from "axios";

export default {
  list(params) {
    return axios.get(`festival/orders/meals${params}`);
  },

  summary(start_date, end_date) {
    return axios.get(
      `festival/orders/meals/summary?start_date=${start_date}&end_date=${end_date}`,
    );
  },

  mark(payload) {
    return axios.post(`festival/orders/meals/mark`, payload);
  },

  search(payload) {
    return axios.get(`festival/orders/meals/search?search=${payload}`);
  },
};
