<template>
  <div>
    <el-dropdown @command="command">
      <slot name="actions-trigger">
        <span class="el-dropdown-link more">
          <i class="eden-icon-more"></i>
        </span>
      </slot>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item :icon="'eden-icon-edit'" :command="'view'"
          >Mark meal status</el-dropdown-item
        >
      </el-dropdown-menu>
    </el-dropdown>
    <eden-confirm-dialog
      title="Mark as picked up"
      button-text="Mark"
      button-type="success"
      :show.sync="showPickedUp"
      @confirm="markMealItem('picked_up')"
      :button-status="marking"
    >
      Are you sure you want to mark this meal as picked up?
    </eden-confirm-dialog>
    <eden-confirm-dialog
      title="Mark as packed"
      button-text="Mark"
      button-type="success"
      :show.sync="showPacked"
      @confirm="markMealItem('packed')"
      :button-status="packing"
    >
      Are you sure you want to mark this meal as packed?
    </eden-confirm-dialog>
    <meal-items-details-dialog
      :mealItem="mealItem"
      :show.sync="showDetails"
      :marking="marking"
      :packing="packing"
      @complete="handleMark($event)"
    />
    <!-- <component
      :is="action.component"
      :action="'edit'"
      :show.sync="action.visibility"
      :mealItem="mealItem"
      @success="emitUpdate"
    /> -->
  </div>
</template>

<script>
import dfMealItems from "@/requests/dance-festival/meal-items.js";
import MealItemsDetailsDialog from "./MealItemsDetailsDialog.vue";

export default {
  components: { MealItemsDetailsDialog },
  name: "MealItemsActions",
  props: {
    mealItem: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      action: {
        component: "",
        command: "",
        visibility: false,
      },
      showPickedUp: false,
      showPacked: false,
      marking: false,
      cancelling: false,
      packing: false,
      showDetails: false,
    };
  },
  computed: {
    componentVisibility() {
      return this.action.visibility;
    },
  },
  watch: {
    componentVisibility() {
      if (!this.action.visibility) {
        this.action.component = "";
      }
    },
  },
  methods: {
    command(command) {
      if (command === "view") {
        this.showDetails = true;
      } else if (command === "form") {
        this.action.visibility = true;
      }
    },
    emitUpdate() {
      this.$emit(
        "update",
        this.action.command === "form" ? "edit" : this.action.command,
      );
    },
    handleMark(status) {
      if (status === "packed") {
        this.showPacked = true;
      } else {
        this.showPickedUp = true;
      }
    },
    markMealItem(status) {
      if (!status) return;

      if (status === "packed") {
        this.packing = true;
      } else {
        this.marking = true;
      }

      dfMealItems
        .mark({
          meal_ids: [this.mealItem.id],
          meal_status: status,
        })
        .then(({ data }) => {
          if (data.status) {
            this.action.visibility = false;
            this.$message.success(data.message);
            this.emitUpdate();
          }
          this.marking = false;
          this.packing = false;
          this.showPickedUp = false;
          this.showPacked = false;
          this.showDetails = false;
        })
        .catch((error) => {
          this.marking = false;
          this.packing = false;
          this.showPickedUp = false;
          this.showPacked = false;
          this.showDetails = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
  },
};
</script>
