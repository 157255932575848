<template>
  <el-dialog
    title="Menu Item Details"
    :visible.sync="shouldShow"
    @close="closeEvent"
    width="40% !important"
  >
    <el-row>
      <el-col :span="24">
        <div>{{ mealItem.name }}</div>
      </el-col>
    </el-row>
    <span slot="footer" class="dialog-footer">
      <el-button
        :type="'plain'"
        :loading="packing"
        :disabled="mealItem.status === 'packed'"
        @click="$emit('complete', 'packed')"
        >Mark status as packed</el-button
      >
      <el-button
        :type="'primary'"
        :loading="marking"
        :disabled="mealItem.status === 'picked_up'"
        @click="$emit('complete', 'picked_up')"
        >Mark status as picked up</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "MealItemsDetailsDialog",
  props: {
    show: Boolean,
    marking: Boolean,
    packing: Boolean,
    mealItem: Object,
  },
  data() {
    return {};
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  color: #21312a;
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 5px;
}

p {
  font-size: 0.75rem;
  margin-bottom: 5px;
}

span {
  font-size: 0.875rem;
  color: #21312a;
  line-height: 1.5;
}

.el-tag {
  height: 26px;
  display: flex;
  align-items: center;
  font-size: 0.75rem;
}
</style>
