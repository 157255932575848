<template>
  <div>
    <el-dropdown @command="command">
      <el-button type="plain">
        Actions <i class="el-icon-arrow-down el-icon--right"></i>
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <template v-for="(action, i) in actions">
          <el-dropdown-item
            v-if="allowAccessFor(action.access)"
            :icon="action.icon"
            :key="i"
            :command="{
              command: action.command,
              component: action.component,
            }"
          >
            {{ action.label }}</el-dropdown-item
          >
        </template>
      </el-dropdown-menu>
    </el-dropdown>
    <meal-item-mark-status-dialog
      :orders="orders"
      :show.sync="show"
      @success="emitUpdate"
    />
  </div>
</template>

<script>
export default {
  name: "MealItemsSelectAction",
  props: {
    orders: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: {
    MealItemMarkStatusDialog: () => import("./MealItemMarkStatusDialog"),
  },
  data() {
    return {
      show: false,
      actions: [
        {
          access: ["admin", "operations", "superadmin"],
          icon: "eden-icon-edit",
          label: "Mark meal status",
          command: "edit",
        },
      ],
    };
  },
  methods: {
    emitUpdate() {
      this.$emit("update");
    },
    command(command) {
      if (command.command === "edit") {
        this.show = true;
      }
    },
  },
};
</script>
